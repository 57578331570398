import React from "react";
import { parseKey } from "../../utils/formatersAndParsers";

export const ErrorField = ({ error, touched }) => {
  return error ? (
    <p className="text-medium text-danger m-0" style={{ marginTop: 2 }}>
      *{parseKey(error)}
    </p>
  ) : (
    ""
  );
};
