import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth/slice";
import commonSlice from "./common/slice";
import storySlice from "./stories/slice";
import queriesSlice from "./queries/slice";
import bookmarksSlice from "./bookmarks/slice";
import otherUserProfileSlice from "./otherUserProfile/slice";

const rootReducer = combineReducers({
  auth: authReducer,
  common: commonSlice,
  stories: storySlice,
  queries: queriesSlice,
  bookmarks: bookmarksSlice,
  otherUserProfile: otherUserProfileSlice,
});

export default rootReducer;
