import React from "react";
import { Col } from "react-bootstrap";

export default function SideSection() {
  return (
    <Col lg={6} className="d-none d-lg-block p-0">
      <div className="authSideImageContainer text-start">
        <div>
          <h3>Connect, Share, Empower</h3>
          <p>
            In an age where technology dominates our daily lives, we are dedicated to provide
            support and resources for those struggling with mental health issues, relationship
            problems, or other challenges.
            <br />
            we believe in the power of sharing emotions and experiences. Founded in 2023, our
            innovative Community Service Program aims to inspire individuals to open up, connect,
            and grow. Join us on a journey of self-discovery and meaningful connections.
          </p>
        </div>
      </div>
    </Col>
  );
}
