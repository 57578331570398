import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { handleApiRequest } from "../../services/handleApiRequest";
import { getStoryReactions } from "../../redux/stories/thunk";
import { defaultPage, reactionsIcons } from "../../utils/constants";
import { getUserImage } from "../../utils/helpers";
import { format_datetime } from "../../utils/formatersAndParsers";

export default function ReactionsListsPop({ userAction, setUserAction }) {
  const [paginationDetails, setPaginationDetails] = useState(defaultPage);
  const [users, setUsers] = useState({});

  const handleClose = () => {
    setUserAction(null);
  };

  const handleReactionsList = async () => {
    const request = { ...paginationDetails, storyId: userAction.storyId };
    const response = await handleApiRequest(getStoryReactions, request);

    if (response.status) {
      setUsers(response.data);
    }
  };

  useEffect(() => {
    if (userAction.storyId) {
      handleReactionsList();
    }
  }, [userAction]);

  return (
    <Modal
      show={!!userAction}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="md"
      className="reportStoryPop"
    >
      <Modal.Body className="p-2 p-sm-3">
        <div className="pt-3">
          <h5 className="text-center m-0">Reactions on this post</h5>

          <div className="my-3 overflow-auto" style={{ maxHeight: "70vh" }}>
            {users.records?.map((reaction) => {
              return (
                <div className="bg-secondary rounded mb-2 p-1 p-sm-2 d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <img
                      src={getUserImage(reaction.user)}
                      alt={reaction.user?.name}
                      onError={(e) => {
                        e.target.src = getUserImage();
                      }}
                      className="img-medium img-fluid"
                    />
                    <div className="ms-2">
                      <p className="my-0">{reaction.user?.name}</p>
                      <p className="muted small my-0">
                        Reacted At: {format_datetime(reaction?.updatedAt)}
                      </p>
                    </div>
                  </div>
                  <span className="reactionIconFromList me-1 me-sm-3">
                    {reactionsIcons[reaction.reactionType]}
                  </span>
                </div>
              );
            })}
          </div>

          <div className="btn-wrap my-2 mt-3">
            <button onClick={handleClose} className="secondaryBtn rounded w-100 py-1">
              Close
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
