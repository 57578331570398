import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ReactComponent as OpenEyeIcon } from "../../../assets/icons/open-eye.svg";
import { ReactComponent as CloseEyeIcon } from "../../../assets/icons/close-eye.svg";
import { parseCamelKey } from "../../../utils/formatersAndParsers";
import { ErrorField } from "../../common/errorField";
import Asterik from "../../common/asterik";

export default function PasswordField({
  field = {},
  disableFields = false,
  values = {},
  handleChange = () => {},
  errors = {},
}) {
  const [showpass, setshowpass] = useState(false);

  const eyetoggle = () => {
    setshowpass(!showpass);
  };

  return (
    <>
      <div>
        <div className=" d-flex align-items-center gap-3 w-100">
          <label htmlFor={field.value}>
            {field.label || parseCamelKey(field.value)} {field.isRequired && <Asterik />}
          </label>
        </div>
        <div className="position-relative">
          <input
            type={showpass ? "text" : "password"}
            id={field.value}
            className="form-control"
            placeholder={field.placeholder || parseCamelKey(field.value)}
            disabled={disableFields}
            name={field.value}
            value={values[field.value]}
            onChange={handleChange}
          />
          <Button variant="" className="eye_btn" onClick={eyetoggle}>
            {showpass ? <OpenEyeIcon /> : <CloseEyeIcon />}
          </Button>
        </div>
        <p className="small muted m-0">
          Use 8 or more characters with a mix of letters, numbers & symbols
        </p>
      </div>
      <ErrorField error={errors[field.value]} />
    </>
  );
}
