import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const register = createAsyncThunk("auth/register", async (data, Thunk) => {
  try {
    let response = await axios.post("auth/register", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const verifyEmail = createAsyncThunk("auth/verifyEmail", async (data, Thunk) => {
  try {
    let response = await axios.post("auth/verifyEmail", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const resendOtp = createAsyncThunk("auth/resendOtp", async (data, Thunk) => {
  try {
    let response = await axios.post("auth/resendOtp", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const login = createAsyncThunk("auth/login", async (data, Thunk) => {
  try {
    let response = await axios.post("auth/login", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const sendOtp = createAsyncThunk("auth/sendOtp", async (data, Thunk) => {
  try {
    let response = await axios.post("auth/sendOtp", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const resetPassword = createAsyncThunk("auth/resetPassword", async (data, Thunk) => {
  try {
    let response = await axios.post("auth/resetPassword", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const logoutUser = createAsyncThunk("auth/logout", async (data, Thunk) => {
  try {
    let response = await axios.get("auth/logout");
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getUserProfile = createAsyncThunk("auth/getUserProfile", async (request, Thunk) => {
  try {
    let response = await axios.get("auth/getUserProfile", request);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const updateUserProfile = createAsyncThunk(
  "auth/updateUserProfile",
  async (request, Thunk) => {
    try {
      let response = await axios.post("auth/updateProfile", request);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const updatePassword = createAsyncThunk("auth/updatePassword", async (request, Thunk) => {
  try {
    let response = await axios.post("auth/changePassword", request);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});
