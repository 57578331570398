import * as yup from "yup";
import { errorMsg } from "../utils/toastMessage";
import { logout } from "../redux/auth/slice";
import store from "../redux/store";

export async function handleApiRequest(
  method,
  request = "",
  showErrorToast = true,
  onError = () => {}
) {
  try {
    const response = await store.dispatch(method(request)).unwrap();
    // console.log("response", response);

    if (response?.status) {
      return response;
    } else {
      onError(response);
      showErrorToast && errorMsg(JSON.stringify(response.message));
      return {};
    }
  } catch (error) {
    console.log("error", error);
    onError(error);
    if (error?.data?.statusCode === 401) {
      const response = store.dispatch(logout());
    }
    if (showErrorToast) {
      if (error instanceof yup.ValidationError) {
        errorMsg(error?.errors);
      } else {
        errorMsg(error?.message);
      }
    }
    return {};
  }
}
