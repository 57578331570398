import React, { useEffect, useState } from "react";
import { handleApiRequest } from "../../services/handleApiRequest";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { SubFooter } from "../../Component/Footer/Footer";
import { getContentPage } from "../../redux/common/thunk";
import ContentPageSkeleton from "../../Component/skeleton/contentPageSkeleton";

export default function ContentPage() {
  const { pageId } = useParams();
  const [pageData, setPageData] = useState({});

  const handlePageDetails = async () => {
    const response = await handleApiRequest(getContentPage, { pageId });
    if (response.status) {
      setPageData(response.data);
    }
  };

  useEffect(() => {
    if (pageId) {
      handlePageDetails();
    }
  }, [pageId]);

  return (
    <>
      <Helmet>
        <title>{`FeelingHub | ${pageData.title}`}</title>
      </Helmet>
      <section className="home">
        {pageData.title ? (
          <div className="mx-2 mx-lg-5 my-4">
            <div className="w-fit border-bottom border-danger mb-4">
              <button className="bg-primary border-0 text-white rounded px-3 py-1 my-3">
                {pageData.title}
              </button>
              <p className="border-top border-danger w-50 mb-1" />
            </div>

            <div dangerouslySetInnerHTML={{ __html: pageData.content }} />
          </div>
        ) : (
          <ContentPageSkeleton />
        )}
        <SubFooter />
      </section>
    </>
  );
}
