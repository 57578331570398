import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { contactUsReason, defaultPage, limitOptions, sortingOptions } from "../../utils/constants";
import { isArray, parseCamelKey } from "../../utils/formatersAndParsers";
import { handleTransformValues } from "../../utils/formatersAndParsers";
import TransparentSelect from "../../Component/common/transparentSelect";
import { handleApiRequest } from "../../services/handleApiRequest";
import AskToShare from "../../Component/askToShare";
import MySelect from "../../Component/common/mySelect";
import MyPagination from "../../Component/common/pagination";
import ReactionsListsPop from "../../Component/Modals/reactionsListPop";
import { getQueriesList } from "../../redux/queries/thunk";
import QuerySkeleton from "../../Component/skeleton/querySkeleton";
import LoadIndicator from "../../Component/Loader";

export default function MyQueries() {
  const { queriesList = {}, loadingQueryList = false } = useSelector(
    (state) => state.queries || {}
  );
  const [userAction, setUserAction] = useState(null);
  const [paginationDetails, setPaginationDetails] = useState({
    ...defaultPage,
    orderBy: sortingOptions[0],
  });
  const [filters, setFilters] = useState({});

  const handleFiltersChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleLimit = (selected) => {
    setPaginationDetails((prev) => ({ ...prev, limit: selected.value }));
  };

  const handleQueries = async () => {
    const selectedFilters = await handleTransformValues(filters);

    const request = {
      ...paginationDetails,
      orderBy: paginationDetails.orderBy?.orderBy,
      ...selectedFilters,
    };
    const response = await handleApiRequest(getQueriesList, request);
  };

  useEffect(() => {
    handleQueries();
  }, [paginationDetails, filters]);

  return (
    <>
      <div className="w-100 overflow-auto">
        {queriesList.totalCount > 0 && (
          <div
            className={`${
              loadingQueryList ? "" : "hide-loader"
            } d-flex align-items-center justify-content-center`}
          >
            <LoadIndicator />
          </div>
        )}
        <div className="storyFilters d-block d-sm-flex align-items-center justify-content-between p-3 px-lg-5">
          <TransparentSelect
            placeholder="Relevance"
            className="sortingSelect"
            options={sortingOptions}
            value={paginationDetails.orderBy}
            onChange={(selected) =>
              setPaginationDetails((prev) => ({
                ...prev,
                orderBy: selected,
                order: selected.order,
              }))
            }
          />
          <TransparentSelect
            placeholder="Filter by reason"
            className="w-230"
            options={[{ value: "", label: "All" }, ...contactUsReason]}
            value={filters.reason}
            onChange={(selected) => handleFiltersChange("reason", selected)}
          />
        </div>

        <div className={`bg-secondary px-2 px-lg-3 pt-3`}>
          {isArray(queriesList.records).length > 0
            ? isArray(queriesList.records).map((query, i) => (
                <div className="pointer bg-secondary position-relative shadow mb-2 py-3 px-2 px-lg-3">
                  <p
                    className={`position-absolute end-0 top-0 m-2 rounded-pill px-3 ${
                      query.status === "active"
                        ? "bg-warning text-dark"
                        : query.status === "closed"
                        ? "bg-danger text-white"
                        : "bg-success text-white"
                    } `}
                  >
                    {parseCamelKey(query.status)}
                  </p>
                  <h5>{contactUsReason.find((item) => item.value === query.reason)?.label}</h5>
                  <p>{query.comment}</p>
                </div>
              ))
            : Array.from({ length: 5 }).map((_) => <QuerySkeleton />)}
          <div className="d-flex justify-content-between py-3">
            <div>
              <MySelect
                classNamePrefix={true ? "darkSelect" : "mySelect"}
                options={limitOptions}
                isSearchable={false}
                value={{ value: paginationDetails.limit, label: paginationDetails.limit }}
                onChange={handleLimit}
              />
            </div>
            <MyPagination
              paginationDetails={paginationDetails}
              setPaginationDetails={setPaginationDetails}
              totalCount={queriesList.totalCount}
              darkPagination={true}
            />
          </div>
        </div>

        <div className="curvatureContainer">
          <div>
            <div id="curved-corner-topright" />
          </div>
          <div>
            <div id="curved-corner-topleft" />
          </div>
        </div>

        <AskToShare />
      </div>
      {userAction?.action === "showReactionsList" && (
        <ReactionsListsPop userAction={userAction} setUserAction={setUserAction} />
      )}
    </>
  );
}
