import React from "react";
import Select from "react-select";

export default function TransparentSelect({ ...props }) {
  return (
    <Select
      styles={{
        option: (base, { isFocused, isSelected }) => ({
          ...base,
          backgroundColor: isSelected
            ? "var(--primary-color)"
            : isFocused
            ? "var(--primary-light-color)"
            : "",
          "&:hover": {
            backgroundColor: "var(--primary-light-color)",
          },
        }),
      }}
      className={`w-100 ${props.className}`}
      classNamePrefix={"transparent_select"}
      components={{
        IndicatorSeparator: () => null,
      }}
      {...props}
    />
  );
}
