import React from "react";
import Drawer from "react-modern-drawer";
import { useSelector } from "react-redux";
import { getUserImage } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { storiesRoute } from "../../routes/routesPath";
import { IoIosArrowForward } from "react-icons/io";

export default function MobileMenu({ userAction, setUserAction }) {
  const navigate = useNavigate();
  const { userProfile } = useSelector((state) => state.auth || {});

  const handleClose = () => {
    setUserAction({});
  };

  return (
    <>
      <Drawer
        open={userAction?.action === "showMobileMenu"}
        onClose={handleClose}
        lockBackgroundScroll={true}
        direction="left"
        className="myAccountDrawer"
      >
        <div className={`profileOptions d-block bg-white text-dark`}>
          <div className="text-white text-center">
            <img
              className="userImage img-fluid object-fit-cover rounded-circle mt-3 mb-1"
              src={getUserImage(userProfile)}
              alt={!userProfile ? "userImage" : userProfile?.name}
              onError={(e) => {
                e.target.src = getUserImage();
              }}
            />
            <p className="m-0">{userProfile?.name}</p>
            <p className="muted small m-0" style={{ lineHeight: "12px" }}>
              {userProfile?.email}
            </p>
          </div>
          <div className="profileItemsList mt-3">
            <div className="mx-2">
              <div className="px-2">
                <p
                  className={`mb-0 pt-2 pb-1 d-flex align-items-center justify-content-between border-bottom border-secondary`}
                  onClick={() => {
                    navigate(storiesRoute);
                    handleClose();
                  }}
                >
                  <span>Stories</span>
                  <span>
                    <IoIosArrowForward />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
