import React, { useEffect, useRef, useState } from "react";
import { reactionsIcons } from "../../utils/constants";
import { BiLike } from "react-icons/bi";
import { handleApiRequest } from "../../services/handleApiRequest";
import { manageStoryReaction } from "../../redux/stories/thunk";
import useAuth from "../../hooks/useAuth";
import { handleLoginPop } from "../../utils/helpers";

export default function AddReaction({
  savedReaction,
  showReaction,
  setShowReaction,
  reactionsCount = 0,
  storyId = "",
  setStoryDetails,
  position = "",
}) {
  const { isAuthenticated } = useAuth();
  const myRef = useRef(null);
  const [myReaction, setMyReaction] = useState("");

  const handleReaction = async (e, reaction) => {
    e.stopPropagation();
    if (!isAuthenticated) return handleLoginPop(true);

    setShowReaction("");
    setMyReaction(reaction);
    const element = document.getElementById(`reactedIcon-${storyId}`);
    if (element) {
      element.classList.remove("popup");
      requestAnimationFrame(() => {
        element.classList.add("popup");
      });
    }

    setStoryDetails((prev) => ({
      ...prev,
      reactionsCount: myReaction ? prev.reactionsCount : (prev.reactionsCount || 0) + 1,
    }));

    const request = { storyId, reaction };
    const response = await handleApiRequest(manageStoryReaction, request);
    if (!response.status) {
      setStoryDetails((prev) => ({
        ...prev,
        reactionsCount: myReaction ? prev.reactionsCount : (prev.reactionsCount || 0) - 1,
      }));
      setMyReaction("");
    }
  };

  useEffect(() => {
    if (!myReaction) {
      setMyReaction(savedReaction);
    }
  }, [storyId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (myRef.current && !myRef.current.contains(event.target)) {
        setShowReaction(false);
      }
    };

    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <p
        onClick={(e) => {
          e.stopPropagation();
          setShowReaction(storyId);
        }}>
        <span id={`reactedIcon-${storyId}`} className="reactedIcon popup">
          {myReaction ? reactionsIcons[myReaction] : <BiLike />}
        </span>
        <span className="d-none d-lg-inline-block">Reacts ({reactionsCount || 0})</span>
      </p>

      <div
        ref={myRef}
        className="reactionOptionContainer d-flex align-items-center"
        style={position === "left" ? { right: 0, left: "unset" } : {}}>
        <span onClick={(e) => handleReaction(e, "like")}>{reactionsIcons.like}</span>
        <span onClick={(e) => handleReaction(e, "love")}>{reactionsIcons.love}</span>
        <span onClick={(e) => handleReaction(e, "laugh")}>{reactionsIcons.laugh}</span>
        <span onClick={(e) => handleReaction(e, "information")}>{reactionsIcons.information}</span>
        <span onClick={(e) => handleReaction(e, "support")}>{reactionsIcons.support}</span>
      </div>
    </div>
  );
}
