import store from "../redux/store";
import { manageLoginPop } from "../redux/common/slice";
import { myAccountRoute, profileRoute } from "../routes/routesPath";
import { femaleAvatarHolder, maleAvatarHolder } from "./constants";

export const getUserImage = (user) => {
  if (!user) return maleAvatarHolder;

  if (user?.avatar?.url) {
    return user.avatar.url;
  }
  if (user?.gender === "female") {
    return femaleAvatarHolder;
  }
  return maleAvatarHolder;
};

export const getMyAccountPagePath = (path) => {
  if (!path) return `${myAccountRoute}${profileRoute}`;
  return `${myAccountRoute}${path}`;
};

export const handleLoginPop = (action) => {
  const dispatch = store.dispatch;
  dispatch(manageLoginPop(action));
};

export const isArray = (array) => {
  if (Array.isArray(array)) return array;
  return [];
};
