import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";

export default function SharePop({ userAction, setUserAction }) {
  const webUrl = "https://feelinghub.in";

  const handleClose = () => {
    setUserAction(null);
  };

  return (
    <Modal
      show={userAction?.action}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="sharePop"
    >
      <Modal.Header closeButton>
        <h5 className="m-0">Share FeelingHub</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <Row>
            <Col sm={6} className="my-2">
              <EmailShareButton url={webUrl} className="pointer">
                <EmailIcon size={32} round={true} />
                <span className="ms-2">
                  Share via <b>Mail</b>
                </span>
              </EmailShareButton>
            </Col>
            <Col sm={6} className="my-2">
              <LinkedinShareButton url={webUrl} className="pointer">
                <LinkedinIcon size={32} round={true} />
                <span className="ms-2">
                  Share on <b>Linkedin</b>
                </span>
              </LinkedinShareButton>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="my-2">
              <PinterestShareButton url={webUrl} className="pointer">
                <PinterestIcon size={32} round={true} />
                <span className="ms-2">
                  Share on <b>Pinterest</b>
                </span>
              </PinterestShareButton>
            </Col>
            <Col sm={6} className="my-2">
              <RedditShareButton url={webUrl} className="pointer">
                <RedditIcon size={32} round={true} />
                <span className="ms-2">
                  Share on <b>Reddit</b>
                </span>
              </RedditShareButton>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="my-2">
              <TelegramShareButton url={webUrl} className="pointer">
                <TelegramIcon size={32} round={true} />
                <span className="ms-2">
                  Share on <b>Telegram</b>
                </span>
              </TelegramShareButton>
            </Col>
            <Col sm={6} className="my-2">
              <TwitterShareButton url={webUrl} className="pointer">
                <TwitterIcon size={32} round={true} />
                <span className="ms-2">
                  Share on <b>Twitter</b>
                </span>
              </TwitterShareButton>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="my-2">
              <WhatsappShareButton url={webUrl} quote="aydyf" className="pointer">
                <WhatsappIcon size={32} round={true} />
                <span className="ms-2">
                  Share on <b>Whatsapp</b>
                </span>
              </WhatsappShareButton>
            </Col>
            <Col sm={6} className="my-2">
              <FacebookShareButton url={webUrl} className="pointer">
                <FacebookIcon size={32} round={true} />
                <span className="ms-2">
                  Share on <b>Facebook</b>
                </span>
              </FacebookShareButton>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="my-2">
              <TwitterShareButton url={webUrl} className="pointer">
                <XIcon size={32} round={true} />
                <span className="ms-2">
                  Share on <b>X</b>
                </span>
              </TwitterShareButton>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}
