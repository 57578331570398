import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { handleApiRequest } from "../../services/handleApiRequest";
import { format_date, isArray } from "../../utils/formatersAndParsers";
import { defaultPage, limitOptions } from "../../utils/constants";
import { getUserImage } from "../../utils/helpers";
import MyPagination from "../../Component/common/pagination";
import { getOtherUserProfile } from "../../redux/otherUserProfile/thunk";
import MySelect from "../../Component/common/mySelect";
import StoryCard from "../../Component/storyCard/mainStoryCard";
import CardSkeleton from "../../Component/skeleton/cardSkeleton";
import UserDetailsSkeleton from "../../Component/skeleton/userDetailsSkeleton";
import { getStoriesList } from "../../redux/stories/thunk";

export default function OtherUserProfile() {
  const { userId } = useParams();

  const [paginationDetails, setPaginationDetails] = useState(defaultPage);
  const [showReaction, setShowReaction] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [stories, setStories] = useState({});

  const handleUserProfile = async () => {
    const response = await handleApiRequest(getOtherUserProfile, { otherUserId: userId });
    if (response.status) {
      setUserDetails(response.data);
    }
  };

  const handleStoriesList = async () => {
    const response = await handleApiRequest(getStoriesList, { ...paginationDetails, user: userId });
    if (response.status) {
      setStories(response.data);
    }
  };

  const handleLimit = (selected) => {
    setPaginationDetails((prev) => ({ ...prev, limit: selected.value }));
  };

  useEffect(() => {
    if (userId) {
      handleUserProfile();
      handleStoriesList();
    }
  }, [userId]);

  return (
    <>
      <section className="">
        {userDetails.name ? (
          <div className="homeStoryCard py-4 px-3 px-lg-5">
            <div className="d-flex align-items-center">
              <img
                src={getUserImage(userDetails)}
                alt={userDetails.name}
                onError={(e) => {
                  e.target.src = getUserImage();
                }}
                className="img-medium img-fluid"
              />
              <div className="ms-2">
                <p className="my-0">{userDetails?.name}</p>
                <p className="muted small my-0">
                  User since: {format_date(userDetails?.createdAt)}
                </p>
              </div>
            </div>
            <p className="m-0 my-3">{userDetails?.bio}</p>
          </div>
        ) : (
          <UserDetailsSkeleton />
        )}

        <div className="curvatureContainer border-0">
          <div className="bg-secondary">
            <div id="curved-corner-topright" className="bg-secondary" />
          </div>
          <div className="bg-secondary">
            <div id="curved-corner-topleft" className="bg-secondary" />
          </div>
        </div>

        <div className="bg-secondary pb-3 px-3 px-lg-5">
          <h4 className="mb-3">Stories</h4>

          <div className={`bg-primary text-white px-3 px-lg-5`}>
            {isArray(stories.records).length > 0
              ? isArray(stories.records).map((story, i) => (
                  <StoryCard
                    key={i}
                    story={story}
                    showReaction={showReaction}
                    setShowReaction={setShowReaction}
                  />
                ))
              : Array.from({ length: 3 }).map((_) => <CardSkeleton />)}
            <div className="d-flex justify-content-between py-3">
              <div>
                <MySelect
                  classNamePrefix={"mySelect"}
                  options={limitOptions}
                  isSearchable={false}
                  value={{ value: paginationDetails.limit, label: paginationDetails.limit }}
                  onChange={(selected) => handleLimit(selected)}
                />
              </div>
              <MyPagination
                paginationDetails={paginationDetails}
                setPaginationDetails={setPaginationDetails}
                totalCount={stories.totalCount}
                darkPagination={false}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
