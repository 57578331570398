import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { homeRoute } from "../../routes/routesPath";
import noPageImage from "../../assets/images/noPage.png";
import Header from "../../Component/Header/Header";
import Footer from "../../Component/Footer/Footer";

const Pagenotfound = () => {
  const navigate = useNavigate();

  setTimeout(() => {
    navigate(homeRoute);
  }, 2000);

  return (
    <>
      <div className="mainWrapper">
        <Header />
        <main className="mainBody" style={{ maxWidth: "unset" }}>
          <section className="">
            <Row
              className="d-flex bg-white align-items-center"
              style={{ height: "calc(100vh - 90px)" }}
            >
              <Col sm={6} className="text-center">
                <img src={noPageImage} alt="" className="img-fluid" />
              </Col>
              <Col sm={6} className="align-self-center">
                <h1 className="m-0">OOPS!</h1>
                <h3 className="fw-bold m-0">PAGE NOT FOUND</h3>
                <p className="extraLarge fw-sbold my-4" style={{ maxWidth: 500 }}>
                  You must have picked the wrong door because I haven't been able to lay my eye on
                  the page you've been searching for.
                </p>
                <Button className="text-uppercase" onClick={() => navigate(homeRoute)}>
                  Back to home
                </Button>
              </Col>
            </Row>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Pagenotfound;
