import React from "react";
import Skeleton from "react-loading-skeleton";

export default function UserDetailsSkeleton({ className = "" }) {
  return (
    <div className={`py-4 px-3 px-lg-5 w-100 ${className}`}>
      <div className="d-flex align-items-center">
        <Skeleton circle className="img-medium" baseColor="#d3d3d3" highlightColor="#fff" />
        <div className="ms-2">
          <Skeleton width={150} baseColor="#d3d3d3" highlightColor="#fff" />
          <Skeleton width={150} baseColor="#d3d3d3" highlightColor="#fff" />
        </div>
      </div>
      <div className="my-2">
        <Skeleton count={2} className="" baseColor="#d3d3d3" highlightColor="#fff" />
      </div>
    </div>
  );
}
