import React from "react";
import Skeleton from "react-loading-skeleton";
import { skeletonBaseColor, skeletonHighlightColor } from "../../utils/constants";

export default function ContentPageSkeleton() {
  return (
    <>
      <div className="mx-2 mx-lg-5 my-4">
        <div className="w-fit border-bottom border-danger mb-4">
          <Skeleton
            baseColor={skeletonBaseColor}
            highlightColor="#fff"
            className="my-3"
            height={28}
            width={150}
          />
          <p className="border-top border-danger w-50 mb-1" />
        </div>

        <div className="my-3">
          <Skeleton
            count={6}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
            className="my-1"
          />
        </div>
        <div className="my-4">
          <Skeleton
            count={3}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
            className="my-1"
          />
        </div>
        <div className="my-3">
          <Skeleton
            count={8}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
            className="my-1"
          />
        </div>
        <div className="my-3">
          <Skeleton
            count={2}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
            className="my-1"
          />
        </div>
      </div>
    </>
  );
}
