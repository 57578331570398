import { createSlice } from "@reduxjs/toolkit";
import { getBookmarksList } from "./thunk";

const initialState = {
  loadingBookmarksList: false,
  bookmarksList: {},
};

const slice = createSlice({
  name: "bookmarks",
  initialState: { ...initialState },
  reducers: {
    updateBookmarksList: (state, action) => {
      state.bookmarksList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookmarksList.pending, (state, action) => {
        state.loadingBookmarksList = true;
      })
      .addCase(getBookmarksList.fulfilled, (state, action) => {
        state.loadingBookmarksList = false;
        state.bookmarksList = action.payload?.data || {};
      })
      .addCase(getBookmarksList.rejected, (state, action) => {
        state.loadingBookmarksList = false;
      });
  },
});

export default slice.reducer;
export const { updateBookmarksList } = slice.actions;
