import store from "../redux/store";

export default function useAuth() {
  const { loggedinUser } = store.getState().auth ?? {};

  return {
    loggedinUser: loggedinUser || {},
    isAuthenticated: loggedinUser?.token ? true : false,
  };
}
