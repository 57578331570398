import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

export const firebaseConfig = {
  apiKey: "AIzaSyCT3Kdu-cw19QNWZk7B75t78BkvqaJpLHw",
  authDomain: "feelinghub-46bd1.firebaseapp.com",
  projectId: "feelinghub-46bd1",
  storageBucket: "feelinghub-46bd1.appspot.com",
  messagingSenderId: "796834940165",
  appId: "1:796834940165:web:e8a04ec2f9cf9b1d2ce36c",
  measurementId: "G-KT4RZ4BCN1",
};

export const vapidKey =
  "BIPm3gtUgpZ91Puh-2t3fmBlfvWgnTBl6wUh6vE_fLeLprvHImtD7NlahQp3jyEc_axBzGLmz9XQIaoZasLGKhQ";

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
