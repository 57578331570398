import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const manageBookmark = createAsyncThunk("stories/manageBookmark", async (data, Thunk) => {
  try {
    let response = await axios.post("bookmark/manage", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getBookmarksList = createAsyncThunk(
  "stories/getBookmarksList",
  async (data, Thunk) => {
    try {
      let response = await axios.post("bookmarks/list", data);
      const records = [];
      for (let item of response.data?.data?.records) {
        records.push(item.story);
      }
      return {
        ...response?.data,
        data: { records, totalCount: response.data?.data?.totalCount || 0 },
      };
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
