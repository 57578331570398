import React, { useEffect, useLayoutEffect } from "react";
import { Toaster } from "react-hot-toast";
import MyRouts from "./routes/routes";
import runAxiosSetup from "./axios";
import { useLocation, useNavigate } from "react-router-dom";
import { handleApiRequest } from "./services/handleApiRequest";
import { getCategories, getContentPages } from "./redux/common/thunk";
import { homeRoute } from "./routes/routesPath";
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase/config";
import useAuth from "./hooks/useAuth";
import { getUserProfile } from "./redux/auth/thunk";
import { useSelector } from "react-redux";
import LoginPop from "./Component/Modals/loginPop";
import usePermissions from "./hooks/usePermissions";

function App() {
  const { loggedinUser, isAuthenticated } = useAuth();
  const {} = usePermissions();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { showLoginPop } = useSelector((state) => state.common);

  onMessage(messaging, (payload) => {
    const { title, body, icon, tag, click_url } = payload.data || {};
    const notificationOptions = { body, icon, tag };

    const notification = new Notification(title, notificationOptions);
    if (click_url) {
      notification.onclick = () => {
        window.open(click_url, "_blank");
      };
    }
  });

  useLayoutEffect(() => {
    runAxiosSetup({
      authorization: loggedinUser?.token ? `Bearer ${loggedinUser?.token}` : undefined,
      userid: loggedinUser?.userId || "",
    });
  }, [loggedinUser]);

  const handleCategoriesList = async () => {
    await handleApiRequest(getCategories);
  };

  const handleContentPagesList = async () => {
    await handleApiRequest(getContentPages);
  };

  const handleUserProfile = async () => {
    await handleApiRequest(getUserProfile);
  };

  useEffect(() => {
    handleCategoriesList();
    handleContentPagesList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isAuthenticated && pathname.includes("auth")) {
      navigate(homeRoute);
    }
  }, [pathname]);

  useEffect(() => {
    if (isAuthenticated) {
      handleUserProfile();
      if (pathname.includes("auth")) {
        navigate(homeRoute);
      }
    }
  }, [isAuthenticated]);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <MyRouts />
      {showLoginPop && <LoginPop />}
    </>
  );
}

export default App;
