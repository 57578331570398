import React from "react";
import Skeleton from "react-loading-skeleton";
import { skeletonBaseColor, skeletonHighlightColor } from "../../utils/constants";

export default function HomeStoryCardSkeleton() {
  return (
    <div className="my-2">
      <div className="homeStoryCard pointer rounded mx-2 px-2 py-4 p-lg-4 text-center">
        <div className="d-flex justify-content-center">
          <Skeleton
            circle
            className="img-medium"
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        </div>
        <Skeleton
          className="my-3"
          height={30}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />
        <Skeleton
          className=""
          count={4}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />
      </div>
    </div>
  );
}
