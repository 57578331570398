import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const uploadFile = createAsyncThunk("common/uploadFile", async (details, Thunk) => {
  try {
    let response = await axios.post(`utility/uploadFiles`, details);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getCategories = createAsyncThunk("common/getCategories", async (details, Thunk) => {
  try {
    let response = await axios.get(`utility/categories/list`);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const manageFirebaseToken = createAsyncThunk(
  "common/manageFirebaseToken",
  async (data, Thunk) => {
    try {
      let response = await axios.post(`utility/firebaseToken/update`, data);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const getContentPages = createAsyncThunk("common/getContentPages", async (data, Thunk) => {
  try {
    let response = await axios.get(`utility/cms/page/list`, data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getContentPage = createAsyncThunk("common/getContentPage", async (data, Thunk) => {
  try {
    let response = await axios.post(`utility/cms/page/content`, data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});
