import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { SkeletonTheme } from "react-loading-skeleton";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-phone-input-2/lib/style.css";
import "ckeditor5/ckeditor5.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-modern-drawer/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.css";
import "./assets/style.css";
import store, { persistor } from "./redux/store";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SkeletonTheme>
      </GoogleOAuthProvider>
    </PersistGate>
  </Provider>
);
