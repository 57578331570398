import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  addStoryRoute,
  communitiesRoute,
  homeRoute,
  signinRoute,
  storiesRoute,
} from "../../routes/routesPath";
import useAuth from "../../hooks/useAuth";
import { getMyAccountPagePath, getUserImage } from "../../utils/helpers";
import MyAccount from "../sidebars/myAccount";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import MobileMenu from "../sidebars/MobileMenuDrawer";
import { MdMenu, MdOutlineAdd } from "react-icons/md";

export default function Header() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { width = 0 } = useWindowDimensions();
  const { userProfile = {} } = useSelector((state) => state.auth || {});

  const [userAction, setUserAction] = useState({});

  return (
    <>
      <header className="headerContainer px-2">
        <div className="header">
          <Link to={homeRoute} className="navbar-brand">
            <div className="d-flex align-items-center">
              <img
                className="mainLogo img-fluid d-none d-md-inline-block"
                src={"/appLogo.png"}
                alt="logo"
              />
              <MdMenu
                className="d-md-none headerIcon"
                onClick={() => setUserAction({ action: "showMobileMenu" })}
              />
              {/* <div style={{ lineHeight: "15px" }}>
                <p className="headerLogoName m-0">Feelinghub</p>
                <p className="small muted m-0">Connect, Share, Empower</p>
              </div> */}
            </div>
          </Link>

          <div className="d-flex align-items-center">
            <div className="me-4 d-none d-md-block">
              <button
                className="textBtn headerLink"
                onClick={() => {
                  navigate(storiesRoute);
                }}
              >
                Stories
              </button>
              <button className="textBtn headerLink" onClick={() => navigate(communitiesRoute)}>
                Support
              </button>
            </div>
            <button
              className="mobileAddStoryBtn textBtn rounded d-md-none d-flex justify-content-center align-items-center"
              onClick={() => navigate(isAuthenticated ? addStoryRoute : signinRoute)}
            >
              <span>
                <MdOutlineAdd />
              </span>
            </button>
            <button
              className="primaryBtn d-none d-md-inline-block rounded-pill py-1 px-2"
              onClick={() => navigate(isAuthenticated ? addStoryRoute : signinRoute)}
            >
              Express your Feeling
            </button>
            {isAuthenticated ? (
              <>
                <img
                  src={getUserImage(userProfile)}
                  alt={userProfile?.name}
                  onError={(e) => {
                    e.target.src = getUserImage();
                  }}
                  className="headerIcon pointer img-fluid mx-2"
                  onClick={() => {
                    if (width >= 768) {
                      navigate(getMyAccountPagePath());
                    } else {
                      setUserAction({ action: "showMyAccountOptions" });
                    }
                  }}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </header>
      <MyAccount userAction={userAction} setUserAction={setUserAction} />
      <MobileMenu userAction={userAction} setUserAction={setUserAction} />
    </>
  );
}
