import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Alignment,
  Autosave,
  BlockQuote,
  Bold,
  ClassicEditor,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Italic,
  List,
  Paragraph,
  SelectAll,
  Table,
  TableToolbar,
} from "ckeditor5";
import React from "react";

export default function CkEditor({ data = "", onChange = () => {} }) {
  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        config={{
          placeholder: "Let your inner voice come out...",
          toolbar: {
            items: [
              "heading",
              "|",
              "fontSize",
              "fontFamily",
              "fontColor",
              "fontBackgroundColor",
              "|",
              "bold",
              "italic",
              "blockQuote",
              "|",
              "alignment",
              "|",
              "bulletedList",
              "numberedList",
              "insertTable",
            ],
          },
          plugins: [
            Alignment,
            Autosave,
            BlockQuote,
            Bold,
            Essentials,
            FontBackgroundColor,
            FontColor,
            FontFamily,
            FontSize,
            Italic,
            List,
            Paragraph,
            SelectAll,
            Table,
            TableToolbar,
            Heading,
          ],
        }}
        data={data}
        onChange={(e, editor) => {
          onChange(editor.getData());
        }}
      />
    </>
  );
}
