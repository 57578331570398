import { createSlice } from "@reduxjs/toolkit";
import { getQueriesList } from "./thunk";

const initialState = {
  queriesList: {},
  loadingQueryList: false,
};

const queriesSlice = createSlice({
  name: "queriesSlice",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQueriesList.pending, (state, action) => {
        state.loadingQueryList = true;
      })
      .addCase(getQueriesList.fulfilled, (state, action) => {
        state.queriesList = action.payload?.data || {};
        state.loadingQueryList = false;
      })
      .addCase(getQueriesList.rejected, (state, action) => {
        state.loadingQueryList = false;
      });
  },
});

export default queriesSlice.reducer;
